import { AppEventName, AppEvents } from 'lib/events/contracts';
import type { PosthogWorkerMessage, WindowShim } from './worker';

import { AppEventTarget } from 'lib/events/globalEvents';
import { loggers } from 'lib/log';

const log = loggers.analytics;

const spawnWorker = (): Worker =>
  (typeof window !== 'undefined' &&
    new Worker(
      /* webpackChunkName: "perc-worker" */ new URL(
        './worker.ts',
        import.meta.url
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    )) as any as Worker;

const postHogIgnoredEvents: Array<AppEventName> = [
  AppEventName.Authenticated,
  AppEventName.ChatwootLoaded,
  AppEventName.CartHydrated,
  AppEventName.CartInitiated,
  AppEventName.CartUpdated
];
const posthogEvents: Array<AppEventName> = Object.values(AppEventName).filter(
  n => !postHogIgnoredEvents.includes(n)
);

export class PostHogService {
  private appEventSubscriptions: Array<() => void>;
  private worker: Worker;

  constructor(readonly eventDispatcher: AppEventTarget) {
    const handlers: Parameters<AppEventTarget['subscribeToMany']>[0] =
      posthogEvents.map(name => [
        name,
        (...args) => this.handleAppEvent(name, ...args)
      ]);
    this.appEventSubscriptions = eventDispatcher.subscribeToMany(handlers);
    this.worker = spawnWorker();

    if (typeof window !== 'undefined') {
      this.worker.addEventListener(
        'message',
        ({ data }: MessageEvent<PosthogWorkerMessage>) => {
          // if (data.type === 'workerError') {
          //   const { message } = data;
          //   log.error(message.error, {
          //     name: message.workerName,
          //     payload: message.payload
          //   });
          // }

          if (data.type === 'beaconPayload') {
            const { beaconPayload } = data;
            if (!(beaconPayload[1] instanceof Blob)) {
              log.error(new Error('Unexpected beacon payload'), {
                beaconPayload
              });
            }
            window.navigator.sendBeacon(...beaconPayload);
          }
        }
      );

      // this.worker.addEventListener('error', error => {
      //   log.error(new Error('Uncaught posthog error'), {
      //     message: error.message
      //   });
      // });

      const { location, screen } = window;
      // TODO: watch screen
      const initM: WindowShim = {
        name: 'window-shim',
        // location is synced in worker via PageViewed event
        location: {
          ancestorOrigins: [],
          hash: location.hash,
          host: location.host,
          hostname: location.hostname,
          href: location.href,
          origin: location.origin,
          pathname: location.pathname,
          port: location.port,
          protocol: location.protocol,
          search: location.search
        },
        referrer: document.referrer,
        screen: {
          availWidth: screen.availWidth,
          availHeight: screen.availHeight,
          width: screen.width,
          height: screen.height,
          colorDepth: screen.colorDepth,
          pixelDepth: screen.pixelDepth,
          // top: screen.top,
          // left: 0,
          // availTop: 32,
          // availLeft: 0,
          orientation: {
            angle: 0,
            type: 'landscape-primary'
          } as ScreenOrientation
        },
        sendBeaconSupported: 'sendBeacon' in window.navigator
      };
      this.worker.postMessage(initM);
    }
  }

  end() {
    for (const unsubscribe of this.appEventSubscriptions) {
      unsubscribe();
    }
    this.worker.terminate();
  }

  private handleAppEvent<Name extends keyof AppEvents>(
    name: Name,
    ...[payload]: AppEvents[Name] extends undefined ? [] : [AppEvents[Name]]
  ) {
    this.worker.postMessage({ name, payload });
  }
}
