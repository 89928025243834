/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

export const IS_DEVELOPMENT_ENV =
  process.env.GATSBY_ACTIVE_ENV === 'development';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noOp = (): void => {};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertUnreachable(_: never): never {
  throw new Error("Didn't expect to get here");
}

export function canUseDom(): boolean {
  return (
    typeof window !== 'undefined' &&
    !!window.document &&
    !!window.document.createElement
  );
}

export function isEmptyRecord(x: unknown): boolean {
  return isRecord(x) && !Object.keys(x).length;
}

export function isRecord(x: unknown): x is Record<string, unknown> {
  return (
    !!x &&
    typeof x === 'object' &&
    (x as Record<string, unknown>).constructor === Object
  );
}

export function isNum(v: any): v is number {
  return typeof v === 'number' && !isNaN(v);
}

// export function isBool(v: any): v is Boolean {
//   return typeof v === 'boolean';
// }

export const isString = (x: unknown): x is string => typeof x === 'string';

// export function isFn(v: any): v is Function {
//   return typeof v === 'function';
// }

export function makeClassNames(
  ...args: Array<
    (string | undefined | null | false)[] | string | null | undefined | false
  >
): string {
  const xs = args.map(x => (typeof x === 'string' ? x.split(' ') : x));
  const set = new Set(xs.flat().filter(Boolean));
  return Array.from(set).join(' ');
}

export function diff<T>(x: T[], y: T[]): T[] {
  if (!x.length) {
    return y;
  }

  if (!y.length) {
    return x;
  }

  return y.reduce((acc, z) => {
    if (!acc.includes(z)) {
      acc.push(z);
    }
    return acc;
  }, x);
}

export const sleep = (n: number): Promise<void> =>
  new Promise(resolve => setTimeout(resolve, n));

export const matchStr = (x: RegExp) => (y: string) => x.test(y);

export const isEmpty = (x: Array<any> | Record<string, unknown>) =>
  Array.isArray(x) ? !x.length : !Object.keys(x).length;

export const omit = <K extends keyof T, T>(
  propsToOmit: K[],
  obj: T
): Omit<T, K> => {
  const willReturn: any = {};

  for (const key in obj) {
    if (!propsToOmit.includes(key as any as K)) {
      willReturn[key] = obj[key];
    }
  }

  return willReturn as Omit<T, K>;
};

export function traverseStringNode(
  fn: (n: string) => React.ReactNode
): (content: React.ReactNode) => React.ReactNode {
  return function op(content: React.ReactNode): React.ReactNode {
    return React.Children.map(content, child => {
      if (typeof child === 'string') {
        return fn(child);
      }

      if (React.isValidElement(child) && child.props.children) {
        return React.cloneElement(child, {
          children: op(child.props.children)
        } as any);
      }

      return child;
    });
  };
}

export const makeStrapiLink = (value?: string) => {
  if (!value) {
    throw Error('Value is required to make link from strapi');
  }

  /**
   * Dynamically adjust between Strapi's local and aws provider
   */
  return /^http/.test(value)
    ? value
    : `${process.env.GATSBY_STRAPI_URL}${value}`;
};

export function isDeepEqual(
  obj1?: Record<any, any> | null | void,
  obj2?: Record<any, any> | null | void
): boolean {
  if (!obj1 && !obj2) {
    return true;
  } else if ((!obj1 && obj2) || (obj1 && !obj2)) {
    return false;
  }

  const isArray1 = Array.isArray(obj1);
  const isArray2 = Array.isArray(obj2);
  if (isArray1 !== isArray2) {
    return false;
  } else if (isArray1 && isArray2) {
    if (obj1.length !== obj2.length) {
      return false;
    }

    for (let index = 0; index < obj1.length; index++) {
      const el1 = obj1[index];
      const el2 = obj2[index];
      if (!isDeepEqual(el1, el2)) {
        return false;
      }
    }

    return true;
  }

  const x = obj1 as Record<any, any>;
  const y = obj2 as Record<any, any>;

  /**
   * Get props
   * and filter `undefined` and `nulls`
   * as a prop which is set to `undefined` will show up in the list
   */
  const props1 = Object.getOwnPropertyNames(x).filter(p => !!x[p]);
  const props2 = Object.getOwnPropertyNames(y).filter(p => !!y[p]);

  if (props1.length != props2.length) {
    return false;
  }

  for (const prop of props1) {
    const bothAreObjects =
      typeof x[prop] === 'object' && typeof y[prop] === 'object';
    if (
      (!bothAreObjects && x[prop] !== y[prop]) ||
      (bothAreObjects && !isDeepEqual(x[prop], y[prop]))
    ) {
      return false;
    }
  }

  return true;
}

export function areSetsEqual<T>(xs: Set<T>, ys: Set<T>) {
  return xs.size === ys.size && [...xs].every(x => ys.has(x));
}
